'use client'

import * as React from 'react'

export type FeedSortingOption = 'your-feed' | 'discovery-feed' | 'un-answered'

export interface FeedSortingContextModel {
  activeSortOption: FeedSortingOption
  setActiveSortOption: (value: FeedSortingOption) => void
}

const FeedSortingContext = React.createContext<FeedSortingContextModel>(null!)

export const FeedSortingProvider = ({ children }: React.PropsWithChildren) => {
  const [activeSortOption, setActiveSortOption] = React.useState<FeedSortingOption>('your-feed')

  return (
    <FeedSortingContext.Provider value={{ activeSortOption, setActiveSortOption }}>
      {children}
    </FeedSortingContext.Provider>
  )
}

export const useFeedSorting = () => {
  const context = React.useContext(FeedSortingContext)

  if (context === undefined) {
    throw new Error('useFeedSorting must be used within a FeedSortingProvider')
  }

  return context
}
