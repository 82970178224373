import * as React from 'react'
import Markdown from 'react-markdown'
import './markdown-viewer.css'

import { UnderlinePlugin } from './plugins/underline.plugin'

export function MarkdownViewer({ children }: { children: string }) {
  const markdown = children
    // Clean from emoji markers due to differences in rendering between react-markdown and our editor
    .replaceAll('```', '')
    // Insert zero-width space between two newlines to prevent the editor from collapsing them, but keep double newlines after lists as
    // a marker to end a list
    .replaceAll(/\n(?= *\n)/g, (match, offset, string) => {
      // If the character before the current '\n' is '\n', we've already passed the first '\n' after the list item
      if (string[offset - 1] === '\n') {
        return '\n&#8287;'
      }

      const prevLineEnd = offset - 1

      const prevLineStart = string.lastIndexOf('\n', prevLineEnd - 1) + 1

      const prevLine = string.substring(prevLineStart, prevLineEnd + 1).trim()

      // Check if the previous line is a list item
      if (/^(-|\d+\.)/.test(prevLine)) {
        // Do not insert '&#8287;' after the first '\n' following a list item
        return '\n'
      } else {
        return '\n&#8287;'
      }
    })
    // Replace all newlines with two spaces and a newline character to prevent the editor from collapsing them (markdown)
    .replaceAll(/\n/gi, '  \n')

  return (
    <Markdown
      className={'markdown-viewer'}
      remarkPlugins={[UnderlinePlugin]}
      components={{
        code: 'u', // Render underline for code due to differences between our editor and react-markdown
      }}
    >
      {markdown}
    </Markdown>
  )
}
