/* eslint-disable @next/next/no-img-element */
import * as React from 'react'
import { IconButton } from 'primitives/buttons/icon-button'
import { useTranslations } from 'next-intl'

import { ChevronRightIcon } from '@ask-cf/assets/icons'

export function PostImageGallery({ imageUrls }: { imageUrls: string[] }) {
  const t = useTranslations('ask_cf.components.feed.post.image_gallery')
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const imgRef = React.useRef<HTMLImageElement>(null)

  React.useEffect(() => {
    if (imgRef.current) {
      imgRef.current.setAttribute('nopin', 'nopin')
    }
  }, [])
  return (
    <div className="relative mt-4 flex w-full justify-center">
      <img
        src={imageUrls[currentIndex]}
        alt="Post image"
        width={0}
        style={{ width: '100%', objectFit: 'contain' }}
        className={'cursor-pointer rounded-[9px]'}
        ref={imgRef}
      />
      {currentIndex > 0 ? (
        <IconButton
          onClick={() => setCurrentIndex(index => --index)}
          variant="transparent"
          className="absolute left-[11px] top-1/2 h-8 w-8 -translate-y-1/2 rotate-180 rounded-full bg-[#050A194D] text-white active:bg-[#050A194D] active:text-white"
          aria-label={t('previous_aria_label')}
          asChild
        >
          <ChevronRightIcon />
        </IconButton>
      ) : null}
      {currentIndex < imageUrls.length - 1 ? (
        <IconButton
          onClick={() => setCurrentIndex(index => ++index)}
          variant="transparent"
          className="absolute right-[11px] top-1/2 h-8 w-8  -translate-y-1/2 rounded-full bg-[#050A194D] text-white active:bg-[#050A194D] active:text-white"
          aria-label={t('next_aria_label')}
          asChild
        >
          <ChevronRightIcon />
        </IconButton>
      ) : null}
    </div>
  )
}
