import * as React from 'react'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'
import { IconButton } from 'primitives/buttons/icon-button'
import { Button } from 'primitives/button'
import { useSearchParams } from 'next/navigation'
import { MarkdownViewer } from 'primitives/markdown-viewer'

import { PostAnswer, ticketGenieUser } from '../models'
import { ShareIcon, ThumbUpIcon, ThumbDownIcon, ChatIcon } from '@ask-cf/assets/icons'
import { PostHeader } from '../../post-header'
import { FadingOverflowContainer } from '@ask-cf/components/fading-overflow-container'
import {
  ASK_CF_FF_ANSWER_LIKES,
  ASK_CF_FF_ANSWER_TIPS,
  ASK_CF_FF_COMMENTS,
  ASK_CF_URL,
} from '@ask-cf/config'
import { useSocialShareDialog } from '@ask-cf/contexts/social-share-dialog-context'
import { TipButton } from '@ask-cf/components/tip-button'
import { PostImageGallery } from './post-image-gallery'
import { useUser } from '@ask-cf/hooks/use-user-data'

export function FeedAnswer({
  answer,
  showExpanded,
  questionDetailsHref,
  showOwnAnswerExpanded,
}: {
  answer: PostAnswer
  showExpanded: boolean
  questionDetailsHref: string
  showOwnAnswerExpanded: boolean
}) {
  const id = useSearchParams().get('display-answer-id')
  const user = useUser()
  const isOwnAnswer = user?.data?.id === answer.user?.id

  const answerRef = React.useRef<HTMLDivElement>(null)

  const detailsWithAnswerId = `${questionDetailsHref}?display-answer-id=${answer.id}`

  React.useEffect(() => {
    if (id && answer.id === id && answerRef.current && showExpanded) {
      const headerHeight = 180
      const elementPosition = answerRef.current.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - headerHeight - 20

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }, [id, answer.id, showExpanded])

  const shouldShowExpanded = showExpanded || (isOwnAnswer && showOwnAnswerExpanded)

  return (
    <div
      className={tw(
        'relative mt-4 flex flex-col gap-4 xl:my-4',
        answer.isBestAnswer ? 'shadow-best-answer rounded-r-2 px-4 pb-4 pt-6 xl:px-6 xl:pb-6' : '',
      )}
      ref={answerRef}
    >
      <div
        className={tw(
          'rounded-l-2 absolute text-sm leading-5',
          answer.isBestAnswer
            ? 'bg-gradient-best-answer bottom-0 left-0 top-0 w-2'
            : 'bg-gradient-answer bottom-2 left-4 top-[54px] w-0.5',
        )}
      />

      <PostHeader
        user={answer.user || ticketGenieUser}
        postedAt={answer.createTime}
        isBestAnswerHeader={answer.isBestAnswer}
      />
      <div className={tw(answer.isBestAnswer ? 'pl-2' : 'pl-10')}>
        <AnswerContent
          content={answer.answer}
          showExpanded={shouldShowExpanded}
          detailsHref={detailsWithAnswerId}
        />
        {answer.assets?.length && shouldShowExpanded ? (
          <PostImageGallery
            imageUrls={answer.assets?.map(asset => asset.optimizedUrl || asset.url)}
          />
        ) : null}
        <div className="mt-4 px-3">
          <AnswerActions answer={answer} detailsHref={detailsWithAnswerId} />
        </div>
      </div>
    </div>
  )
}

function AnswerContent({
  content,
  detailsHref,
  showExpanded,
}: {
  content?: string
  detailsHref: string
  showExpanded?: boolean
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  if (!content) {
    return null
  }

  const AnswerDisplay = () => (
    <div className="text-text-secondary xl:text-md whitespace-pre-wrap text-sm leading-5 [overflow-wrap:anywhere]">
      <MarkdownViewer>{content}</MarkdownViewer>
    </div>
  )

  return showExpanded ? (
    <AnswerDisplay />
  ) : (
    <FadingOverflowContainer viewMoreHref={detailsHref} viewMoreLabel={t('view_full_answer_label')}>
      <AnswerDisplay />
    </FadingOverflowContainer>
  )
}

function AnswerActions({ answer, detailsHref }: { answer: PostAnswer; detailsHref: string }) {
  const absoluteDetailsHref = `${ASK_CF_URL}${detailsHref}`
  const t = useTranslations('ask_cf.components.feed.post')
  const { openDialog } = useSocialShareDialog()
  return (
    <div className="flex items-center gap-4">
      {ASK_CF_FF_ANSWER_LIKES ? (
        <div className="text-text-tertiary flex items-center gap-2 rounded-[100px] bg-[#F3F5F9] px-3 py-[5px]">
          <>
            <IconButton
              variant="transparent"
              className="text-text-tertiary hover:text-text-tertiary p-0"
              aria-label={t('thumb_up_aria_label')}
              asChild
            >
              <ThumbUpIcon />
            </IconButton>
            <div className="text-sm leading-5 ">{answer.upvotes}</div>
            <IconButton
              variant="transparent"
              className="text-text-tertiary hover:text-text-tertiary p-0"
              aria-label={t('thumb_down_aria_label')}
              asChild
            >
              <ThumbDownIcon />
            </IconButton>
          </>
        </div>
      ) : null}
      <div className="text-text-tertiary flex items-center gap-5 text-sm font-medium leading-5">
        {ASK_CF_FF_ANSWER_TIPS ? <TipButton /> : null}
        {ASK_CF_FF_COMMENTS ? (
          <Button
            variant="invisible"
            className="hover:text-text-hover flex items-center gap-1 p-0"
            aria-label={t('comment_label')}
          >
            <ChatIcon />
            <span className="hidden xl:inline-block">{t('comment_label')}</span>
          </Button>
        ) : null}
        <Button
          variant="invisible"
          className="hover:text-text-hover flex items-center gap-1 p-0"
          onClick={() => openDialog(absoluteDetailsHref)}
          aria-label={t('share_answer_label')}
        >
          <ShareIcon width={16} height={16} />
          <span className="hidden xl:inline-block">{t('share_answer_label')}</span>
        </Button>
      </div>
    </div>
  )
}
