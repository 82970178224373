export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

/**
 * Returns a value linearly interpolated from two known points based on the given interval.
 */
export function lerp(a: number, b: number, t: number) {
  return a + (b - a) * t
}

/**
 * Returns the percentage between two know points based on a given interval.
 */
export function lerpPercentage(a: number, b: number, t: number) {
  return ((t - a) / (b - a)) * 100
}

export function toFixed(value: number, precision = 2) {
  let num = value.toString()
  num = num.slice(0, num.indexOf('.') + precision + 1)

  return Number(num)
}

export const animateProgress = (
  start: number,
  target: number,
  duration: number,
  handleProgress: (progress: number) => void,
  callback?: () => void,
) => {
  let startTime: number | null = null
  let animationFrameId: number | null = null
  let isCancelled = false

  const update = (timestamp: number) => {
    if (isCancelled) {
      return
    }

    if (!startTime) {
      startTime = timestamp
    }

    const elapsed = timestamp - startTime
    const t = Math.min(elapsed / duration, 1)

    const currentProgress = lerp(start, target, t)
    const clampedProgress = clamp(currentProgress, 0, target) * 100

    handleProgress(clampedProgress)

    if (!isCancelled && currentProgress < target) {
      animationFrameId = requestAnimationFrame(update)
    } else if (!isCancelled) {
      callback?.()
    }
  }

  if (!isCancelled) {
    animationFrameId = requestAnimationFrame(update)
  }

  return () => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId)
    }

    isCancelled = true

    return isCancelled
  }
}

export function getRangePosition(value: number, min: number, max: number) {
  if (min === max) {
    return 0
  }

  return (value - min) / (max - min)
}
