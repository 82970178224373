'use client'
/* eslint-disable @next/next/no-img-element */
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import { Button } from 'primitives/button'
import * as DropdownMenu from 'primitives/dropdown'
import { TickIcon } from 'primitives/icons'
import * as React from 'react'
import { tw } from 'utils/classnames'
import { useIsLoggedInQuery } from 'auth/hooks'
import { MarkdownViewer } from 'primitives/markdown-viewer'

import { Link } from '@ask-cf/components/link'
import { FlagIcon, MoreIcon, RssFeedIcon, ShareIcon, StylusIcon } from '@ask-cf/assets/icons'
import { FadingOverflowContainer } from '@ask-cf/components/fading-overflow-container'
import { StyledButton } from '@ask-cf/components/styled-button'
import { StyledLink } from '@ask-cf/components/styled-link'
import { ASK_CF_FF_ADD_ANSWER, ASK_CF_FF_FOLLOW, ASK_CF_URL } from '@ask-cf/config'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { TOPICS_LIST } from '@ask-cf/constants/topics'
import { useAnswerDialog } from '@ask-cf/contexts/answer-dialog-context'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'
import { useReportQuestionDialog } from '@ask-cf/contexts/report-question-dialog-context'
import { useSocialShareDialog } from '@ask-cf/contexts/social-share-dialog-context'
import { PostHeader } from '../../post-header'
import AddAnswerImage from '../assets/add-answer.svg'
import { Post, PostAnswer } from '../models'
import { FeedAnswer } from './feed-answer'
import { PostImageGallery } from './post-image-gallery'
import { GoogleQuestionAnswerJson } from './question-answer-json'

const showNotReadySections = false

const FeedPost = React.forwardRef<
  HTMLDivElement,
  { post: Post; showExpanded?: boolean; showOwnAnswerExpanded?: boolean }
>(
  (
    {
      post,
      showExpanded = false,
      showOwnAnswerExpanded = false,
    }: { post: Post; showExpanded?: boolean; showOwnAnswerExpanded?: boolean },
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const t = useTranslations('ask_cf.components.feed.post')
    const locale = useLocale()
    const topic = TOPICS_LIST.find(t => t.slug === post.topicSlug)
    const detailsHref = ROUTES.QUESTION_DETAILS(topic?.slug || '', post.questionSlug, locale)

    const contentRef = React.useRef<HTMLDivElement>(null)
    const [isOverflowing, setIsOverflowing] = React.useState(false)

    React.useLayoutEffect(() => {
      const contentElement = contentRef.current
      if (contentElement) {
        const contentHeight = contentElement.scrollHeight
        const lineHeight = parseFloat(getComputedStyle(contentElement).lineHeight)
        const maxAllowedHeight = lineHeight * 6 // 6 lines height

        if (contentHeight > maxAllowedHeight) {
          setIsOverflowing(true)
        } else {
          setIsOverflowing(false)
        }
      }
    }, [post.question])

    return (
      <>
        <GoogleQuestionAnswerJson question={post} />
        <div className="rounded-2 relative w-full bg-white pb-4 pt-6 xl:pb-6" ref={ref}>
          <div className="px-4 xl:px-6">
            {post.reason && !showExpanded ? (
              <div className="flex flex-col">
                <div className="text-text-tertiary flex flex-row items-center text-xs leading-4 xl:text-sm xl:leading-5">
                  <span className="flex-1">{post.reason}</span>
                  {showNotReadySections ? <PostCardMenu postId={post.id} /> : null}
                </div>
                <div className="mb-4 mt-2 h-[1px] w-full bg-gray-400 bg-opacity-50" />
              </div>
            ) : showNotReadySections ? (
              <div className="absolute right-4 top-0 xl:right-6">
                <PostCardMenu postId={post.id} />
              </div>
            ) : null}
            <PostHeader user={post.user} topic={topic} postedAt={post.createTime} />
            <>
              {showExpanded ? (
                <h1 className="pt-5 text-xl font-medium leading-[28px] text-blue-900">
                  {post.question}
                </h1>
              ) : (
                <>
                  <div ref={contentRef}>
                    <StyledLink
                      href={detailsHref}
                      target="_blank"
                      className={tw(
                        'text-md pt-5 font-medium leading-6 text-blue-900',
                        isOverflowing &&
                          ' relative block max-h-[135px] overflow-hidden [overflow-wrap:anywhere]',
                      )}
                    >
                      {isOverflowing ? (
                        <div className="z-2 absolute h-full w-full bg-gradient-to-b from-transparent to-white" />
                      ) : null}
                      <h2 className={tw(isOverflowing && 'h-[113px]')}>{post.question}</h2>
                    </StyledLink>
                  </div>
                  {isOverflowing && !post.context ? (
                    <StyledLink
                      href={detailsHref}
                      target="_blank"
                      className="text-content-text-link hover:text-text-hover block text-sm leading-5"
                    >
                      {t('read_more_label')}
                    </StyledLink>
                  ) : null}
                </>
              )}
              <PostContext
                context={post?.context || ''}
                showExpanded={showExpanded}
                detailsHref={detailsHref}
              />
              {post.assets?.length ? (
                <PostImageGallery
                  imageUrls={post.assets?.map(asset => asset.optimizedUrl || asset.url)}
                />
              ) : null}
            </>
          </div>
          <PostActions
            answersCount={post?.answers?.length || 0}
            isFollowed={post.isFollowed}
            detailsHref={detailsHref}
            post={post}
          />
          <Divider />
          <PostAnswersSection
            answers={post?.answers || []}
            showExpanded={showExpanded}
            questionDetailsHref={detailsHref}
            showOwnAnswerExpanded={showOwnAnswerExpanded}
            post={post}
          />
        </div>
      </>
    )
  },
)

function PostContext({
  context,
  detailsHref,
  showExpanded,
}: {
  context?: string
  detailsHref: string
  showExpanded?: boolean
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  if (!context) {
    return null
  }

  const ContextDisplay = () => <MarkdownViewer>{context}</MarkdownViewer>

  return showExpanded ? (
    <div className="text-text-context mt-3 overflow-hidden text-sm leading-5 [overflow-wrap:anywhere] xl:mt-4">
      <ContextDisplay />
    </div>
  ) : (
    <FadingOverflowContainer
      viewMoreHref={detailsHref}
      viewMoreLabel={t('view_more_label')}
      className="mt-3 text-sm xl:mt-4"
    >
      <ContextDisplay />
    </FadingOverflowContainer>
  )
}

function PostActions({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  post,
  answersCount,
  isFollowed,
  detailsHref,
}: {
  post: Post
  answersCount: number
  isFollowed?: boolean
  detailsHref: string
}) {
  const { openDialog } = useSocialShareDialog()
  const absoluteDetailsHref = `${ASK_CF_URL}${detailsHref}`
  const { openDialog: openAnswerDialog } = useAnswerDialog()
  const t = useTranslations('ask_cf.components.feed.post')
  const isLoggedInQuery = useIsLoggedInQuery()
  const { openLoginDialog } = useNotifyLoginDialog()

  function handleAddAnswer() {
    if (!isLoggedInQuery.isLoading && !isLoggedInQuery.data) {
      openLoginDialog(t('log_in_dialog_title'))
    } else {
      openAnswerDialog(post)
    }
  }

  return (
    <div className="mt-7">
      <div className="flex w-full justify-center gap-4 px-3 xl:justify-start xl:px-6">
        <Link
          href={detailsHref}
          target="_blank"
          className="flex flex-col items-center justify-between xl:flex-row xl:gap-1.5"
        >
          <div className="text-content-text-link flex h-[22px] min-w-[22px] items-center justify-center rounded-full bg-blue-300 px-1.5 text-sm">
            {answersCount}
          </div>
          <div className="text-text-tertiary hover:text-text-hover text-xs font-medium leading-4 xl:text-sm xl:leading-5">
            {answersCount === 1 ? t('answers_label_singular') : t('answers_label_plural')}
          </div>
        </Link>
        {ASK_CF_FF_ADD_ANSWER ? (
          <PostActionButton
            label={t('add_action_label')}
            icon={<StylusIcon />}
            onClick={handleAddAnswer}
          />
        ) : null}
        {ASK_CF_FF_FOLLOW ? (
          <PostActionButton
            label={isFollowed ? t('followed_question_label') : t('follow_question_label')}
            className={isFollowed ? '!text-content-text-link' : ''}
            icon={isFollowed ? <TickIcon /> : <RssFeedIcon />}
          />
        ) : null}
        <PostActionButton
          label={t('share_label')}
          icon={<ShareIcon />}
          className={'ml-auto'}
          onClick={() => openDialog(absoluteDetailsHref)}
        />
      </div>
    </div>
  )
}

function PostActionButton({
  icon,
  label,
  onClick,
  className,
}: {
  icon: React.ReactNode
  label: string
  onClick?: () => void
  className?: string
}) {
  const isLoggedInQuery = useIsLoggedInQuery()

  return (
    <Button
      variant="invisible"
      onClick={onClick}
      className={tw(
        'text-text-tertiary hover:text-text-hover flex flex-col items-center gap-0 p-0 xl:flex-row xl:gap-1.5',
        className,
      )}
      aria-label={label}
      disabled={isLoggedInQuery.isLoading}
    >
      {icon}
      <span className="text-xs font-medium leading-4 xl:text-sm xl:leading-5">{label}</span>
    </Button>
  )
}

function Divider() {
  return (
    <div className="mt-3 xl:px-6">
      <div className="h-[1px] w-full bg-gray-400 bg-opacity-50" />
    </div>
  )
}

function AnswersDivider() {
  return (
    <div className="hidden h-[40px] items-center xl:flex">
      <div className="h-[1px] w-full bg-gray-400 bg-opacity-50" />
    </div>
  )
}

function AddAnswerButton({ onClick }: { onClick?: () => void }) {
  const t = useTranslations('ask_cf.components.feed.post')

  return (
    <button
      onClick={onClick}
      className="bg-background-primary rounded-2 mt-4 flex w-full flex-col items-center gap-1 py-2 text-sm font-medium leading-5 text-gray-600"
    >
      <Image
        alt={t('add_answer')}
        src={AddAnswerImage.src}
        className={'h-[65px] w-[72px]'}
        width="72"
        height="65"
      />
      <span>{t('add_answer')}</span>
    </button>
  )
}

function PostAnswersSection({
  answers,
  showExpanded,
  questionDetailsHref,
  showOwnAnswerExpanded,
  post,
}: {
  answers: PostAnswer[]
  showExpanded: boolean
  questionDetailsHref: string
  showOwnAnswerExpanded: boolean
  post: Post
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  const [shownAnswersCount, setShownAnswersCount] = React.useState(showExpanded ? 20 : 1)
  const bestAnswer = React.useMemo(() => answers.find(answer => answer.isBestAnswer), [answers])
  const nonBestAnswers = React.useMemo(
    () => answers.filter(answer => !answer.isBestAnswer),
    [answers],
  )
  const moreAnswersCount = nonBestAnswers.length - shownAnswersCount

  const shownNonBestAnswers = nonBestAnswers.slice(0, shownAnswersCount)

  const nextAnswer = nonBestAnswers[shownAnswersCount]

  const detailsWithAnswerId = `${questionDetailsHref}?display-answer-id=${nextAnswer?.id}`

  const handleShowMoreAnswers = () => {
    if (showExpanded) {
      setShownAnswersCount(shownAnswersCount + 20)
    } else {
      window.open(detailsWithAnswerId, '_blank', 'noopener,noreferrer')
    }
  }

  const { openDialog: openAnswerDialog } = useAnswerDialog()
  const isLoggedInQuery = useIsLoggedInQuery()
  const { openLoginDialog } = useNotifyLoginDialog()

  function handleAddAnswer() {
    if (!isLoggedInQuery.isLoading && !isLoggedInQuery.data) {
      openLoginDialog('You have to be logged in to answer a question')
    } else {
      openAnswerDialog(post)
    }
  }

  return (
    <>
      <div className={'px-3 xl:px-6'} id="answer-section">
        {ASK_CF_FF_ADD_ANSWER && !answers?.length ? (
          <AddAnswerButton onClick={handleAddAnswer} />
        ) : null}
        {bestAnswer ? (
          <>
            <React.Suspense>
              <FeedAnswer
                answer={bestAnswer}
                showExpanded={showExpanded}
                questionDetailsHref={questionDetailsHref}
                showOwnAnswerExpanded={showOwnAnswerExpanded}
              />
            </React.Suspense>
            {shownNonBestAnswers.length ? <AnswersDivider /> : null}
          </>
        ) : null}
        {shownNonBestAnswers.map((answer, index) => (
          <React.Fragment key={answer.id}>
            <React.Suspense>
              <FeedAnswer
                key={answer.id}
                answer={answer}
                showExpanded={showExpanded}
                questionDetailsHref={questionDetailsHref}
                showOwnAnswerExpanded={showOwnAnswerExpanded}
              />
            </React.Suspense>
            {index !== shownNonBestAnswers.length - 1 ? <AnswersDivider /> : null}
          </React.Fragment>
        ))}
      </div>
      {moreAnswersCount > 0 ? (
        <div className="mt-4 flex items-center justify-center border-t border-gray-400 border-opacity-50 pt-5 xl:mx-6">
          <StyledButton
            noAnimation
            variant="transparent"
            size="minimal"
            className="text-sm font-medium leading-5 text-blue-700"
            onClick={handleShowMoreAnswers}
          >
            {t(
              moreAnswersCount === 1 ? 'more_answers_label_singural' : 'more_answers_label_plural',
              { moreAnswersCount },
            )}
          </StyledButton>
        </div>
      ) : null}
    </>
  )
}

export const PostCardMenu = ({ postId }: { postId: string }) => {
  const [isOpened, setIsOpened] = React.useState(false)
  const { openDialog } = useReportQuestionDialog()
  const t = useTranslations('ask_cf.components.feed.post')

  React.useEffect(() => {
    const closeDropdown = () => setIsOpened(false)
    if (isOpened) {
      setTimeout(() => window.addEventListener('click', closeDropdown), 10)
      return () => window.removeEventListener('click', closeDropdown)
    }
  }, [isOpened])

  return (
    <DropdownMenu.Root open={isOpened}>
      <DropdownMenu.Trigger aria-label="notifications" onClick={() => setIsOpened(x => !x)}>
        <MoreIcon className="text-text-tertiary hover:text-text-hover" />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="shadow-dropdown flex -translate-x-[calc(50%-12px)] flex-col rounded-b bg-white px-4 py-3">
          <DropdownMenu.Item
            className="text-text-tertiary hover:text-text-hover flex cursor-pointer flex-row items-center gap-1"
            onClick={() => openDialog(postId)}
          >
            <FlagIcon />
            <span className="text-sm leading-[18.9px]">{t('report_question')}</span>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

FeedPost.displayName = 'FeedPost'
export { FeedPost }
