export function sanitizeSchema<T extends object>(schema: T): void {
  Object.keys(schema).forEach(key => {
    const value = (schema as Record<string, unknown>)[key]
    if (value === undefined || value === null) {
      delete (schema as Record<string, unknown>)[key]
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      sanitizeSchema(value as object)
    }
  })
}
