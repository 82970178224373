import * as React from 'react'
import { useIsLoggedInQuery } from 'auth/hooks'

import { FeedPost } from './components/feed-post'
import { FeedSortButtons } from './components/feed-sort-buttons'
import { Post } from './models'
import { ASK_CF_FF_FEED_SORT, IS_PROD_ENV } from '@ask-cf/config'
import { PostSkeleton } from './components/post-skeleton'
import { GoogleAd } from '../google-ads'

const AD_PLACEMENT_INTERVAL = 3

export function Feed({
  posts,
  hideSortButtons = false,
  loading,
  nextPage,
  hasMore,
  showOwnAnswerExpanded,
}: {
  posts: Post[]
  hideSortButtons?: boolean
  loading?: boolean
  nextPage?: () => void
  hasMore?: boolean
  showOwnAnswerExpanded?: boolean
}) {
  const lastPostElementRef = React.useRef<HTMLDivElement>(null)
  const observerRef = React.useRef<IntersectionObserver | null>(null)

  const { data: isLoggedIn } = useIsLoggedInQuery()

  // Start observing the last post to detect when it's in the viewport
  React.useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect()
    }

    observerRef.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && nextPage && !loading && hasMore) {
          nextPage()
        }
      },
      {
        root: null,
        rootMargin: '500px',
        threshold: 0,
      },
    )

    if (lastPostElementRef.current) {
      observerRef.current.observe(lastPostElementRef.current)
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [posts, nextPage, loading, hasMore])

  return (
    <div className="w-full flex-grow">
      {!hideSortButtons && isLoggedIn && ASK_CF_FF_FEED_SORT ? (
        <div className="hidden xl:mb-6 xl:block">
          <FeedSortButtons />
        </div>
      ) : null}
      <div className="flex w-full flex-col gap-4">
        {posts.map((post, index) => (
          <div key={index} className="feed-post">
            {posts.length === index + 1 ? (
              <FeedPost
                ref={lastPostElementRef}
                post={post}
                showOwnAnswerExpanded={showOwnAnswerExpanded}
              />
            ) : (
              <FeedPost post={post} showOwnAnswerExpanded={showOwnAnswerExpanded} />
            )}
            {(index + 1) % AD_PLACEMENT_INTERVAL === 0 && IS_PROD_ENV ? (
              <FeedAd key={`ad-${index}`} adSlot="1090751317" />
            ) : null}
          </div>
        ))}
        {loading ? <FeedPostSkeletons /> : null}
      </div>
    </div>
  )
}

function FeedAd({ adSlot }: { adSlot: string }) {
  return (
    <>
      <GoogleAd
        adClient="ca-pub-8093072971929641"
        adSlot={adSlot}
        style={{ display: 'inline-block', width: '100%', height: '90px' }}
        adFormat="fluid"
        layoutKey="-ft+t+66-3m+j"
      />
    </>
  )
}

function FeedPostSkeletons() {
  return (
    <>
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
      <PostSkeleton />
    </>
  )
}
