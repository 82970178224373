import { motionValue } from 'framer-motion'
import { useConstant } from 'hooks/use-constant'
import * as React from 'react'
import { nextFrame } from 'utils/dom'
import { getRangePosition } from 'utils/math'

export const useBrowserLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

const createMotionValues = () => ({
  value: motionValue(0),
})

interface UseLinearProgressOptions {
  from: number
  to: number
  // in milliseconds
  duration: number
  // in milliseconds
  delay?: number
}

export function useLinearProgress({ from, to, duration, delay }: UseLinearProgressOptions) {
  const values = useConstant(createMotionValues)

  useBrowserLayoutEffect(() => {
    return measure(
      ({ value }) => {
        values.value.set(value)
      },
      {
        from,
        to,
        duration,
        delay: delay ?? 0,
      },
    )
  }, [duration, from, to, values])

  return values
}

interface ScrollInfo {
  value: number
}

interface MeasureCallback {
  (info: ScrollInfo): void
}

interface MeasureOptions {
  from: number
  to: number
  duration: number
  delay: number
}

function measure(callback: MeasureCallback, { to, from, duration, delay }: MeasureOptions) {
  let stopped = false

  function doMeasure() {
    const start = Date.now()

    function work() {
      const now = Date.now()
      const elapsed = now - start
      const progress = Math.max(0, Math.min(elapsed / duration, duration))

      const value = getRangePosition((to - from) * progress, from, to)

      callback({ value })

      if (elapsed < duration && !stopped) {
        nextFrame(work)
      }
    }

    work()
  }

  setTimeout(doMeasure, delay)

  return () => {
    stopped = true
  }
}
