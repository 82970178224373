import visit from 'unist-util-visit'
import { Root, Text } from 'mdast'
import { Node } from 'unist'

export const UnderlinePlugin = () => {
  return (tree: Root) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visit(tree, 'text', (node: Node, index: number | null, parent: any) => {
      const textNode = node as Text

      if (textNode.value && parent) {
        const regex = /``(.*?)``/g
        const matches = Array.from(textNode.value.matchAll(regex))

        if (matches.length > 0) {
          const newChildren: Node[] = []
          let lastIndex = 0

          matches.forEach(match => {
            const [fullMatch, content] = match
            const startIndex = match.index ?? 0

            if (startIndex > lastIndex) {
              newChildren.push({
                type: 'text',
                value: textNode.value.slice(lastIndex, startIndex),
              } as Node)
            }

            // Underline node
            newChildren.push({
              type: 'element',
              tagName: 'u',
              children: [{ type: 'text', value: content }],
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any)

            lastIndex = startIndex + fullMatch.length
          })

          if (lastIndex < textNode.value.length) {
            newChildren.push({
              type: 'text',
              value: textNode.value.slice(lastIndex),
            } as Node)
          }

          if (parent && typeof index === 'number') {
            parent.children.splice(index, 1, ...newChildren)
          }
        }
      }
    })
  }
}
