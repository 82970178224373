import { PostAnswer } from '../models'
import { sanitizeSchema } from '@ask-cf/utils/json-ld-helpers'

type GoogleQuestionAnswerJsonProps = {
  question: {
    question: string
    context?: string | null
    totalAnswers?: number
    createTime: string
    answers?: PostAnswer[] | null
  }
}

export function GoogleQuestionAnswerJson(props: GoogleQuestionAnswerJsonProps) {
  const { question } = props
  const { answers } = question

  const suggestedAnswer = answers?.find(answer => answer?.isGenieAnswer) || null

  const jsonLdSchema = {
    '@context': 'https://schema.org',
    '@type': 'QAPage',
    mainEntity: {
      '@type': 'Question',
      name: question.question,
      text: question?.context || '',
      answerCount: question?.totalAnswers || 0,
      datePublished: new Date(question.createTime).toISOString(),
      suggestedAnswer: suggestedAnswer
        ? {
            '@type': 'Answer',
            text: suggestedAnswer.answer || '',
            datePublished: new Date(suggestedAnswer.createTime).toISOString(),
          }
        : undefined,
    },
  }

  // Sanitize the JSON-LD schema by removing undefined or null properties
  sanitizeSchema(jsonLdSchema)

  return (
    <script
      key="google-question-answers-structured-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdSchema) }}
    />
  )
}
