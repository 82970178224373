import { SkeletonItem } from 'components/skeleton-item'

export function PostSkeleton() {
  return (
    <div className="rounded-2 flex w-full flex-col bg-white p-6">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row gap-1">
          <SkeletonItem className="h-10 w-10 rounded-full" />
          <div>
            <SkeletonItem className="w-[145px]" />
            <SkeletonItem className="mt-[3px] h-[17px] w-[99px]" />
          </div>
        </div>
        <SkeletonItem className="w-[86px]" />
      </div>
      <SkeletonItem className="mt-[17px] h-[40px]" />
      <SkeletonItem className="mt-6 h-[40px]" />
      <SkeletonItem className="mt-6 h-[40px]" />
      <SkeletonItem className="mt-6 h-[40px] w-[398px]" />
      <div className="mt-[49px] flex gap-6">
        <SkeletonItem className="w-[88px]" />
        <SkeletonItem className="w-[111px]" />
        <SkeletonItem className="w-[137px]" />
        <SkeletonItem className="ml-auto w-[18px]" />
      </div>
    </div>
  )
}
