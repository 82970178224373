import * as React from 'react'
import { useTranslations } from 'next-intl'
import { Button } from 'primitives/button'
import { cubicBezier, motion, useTransform, AnimatePresence } from 'framer-motion'
import { useConstant } from 'hooks/use-constant'
import { useLinearProgress } from 'hooks/use-linear-progress'
import { useUserCoinBalanceQuery } from 'codegen/generated/coins'
import { useIsLoggedInQuery } from 'auth/hooks'

import { StarCircleIcon, CoinIcon } from '@ask-cf/assets/icons'
import { randomBetween } from '@ask-cf/utils/math'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'

export function TipButton() {
  const t = useTranslations('ask_cf.components.tip_button')
  const spread = 350
  const duration = 1250
  const [coins, setCoins] = React.useState<number[]>([])
  // eslint-disable-next-line local/codegen-query-patterns
  const userBalance = useUserCoinBalanceQuery()
  const isLoggedInQuery = useIsLoggedInQuery()
  const { openLoginDialog } = useNotifyLoginDialog()

  function onAnimate() {
    const next = [
      randomBetween(0, 0.1),
      randomBetween(0, 0.2),
      ...Array(5 - 2)
        .fill(0)
        .map(() => randomBetween(0, spread)),
    ]

    setCoins(coins => [...coins, ...next])

    setTimeout(() => {
      setCoins(coins => coins.filter(coin => !next.includes(coin)))
    }, duration + spread)
  }

  const balance = React.useMemo(
    () => userBalance.data?.userBalance.balance || 0,
    [userBalance.data?.userBalance.balance],
  )
  const notEnoughCoins = Boolean(balance < 5)

  function handleClickGiveTip() {
    if (!isLoggedInQuery.isLoading && !isLoggedInQuery.data) {
      openLoginDialog(t('log_in_dialog_title'))
      return
    } else {
      onAnimate()
    }
  }

  const isDisabledButton = notEnoughCoins && isLoggedInQuery.data

  return (
    <div>
      <div className="relative z-[100]">
        <AnimatePresence>
          {coins.map(delay => (
            <CoinsAnimation key={delay} delay={delay} duration={duration} />
          ))}
        </AnimatePresence>
      </div>
      <Button
        variant="invisible"
        disabled={isDisabledButton}
        className="active:text-text-tertiary hover:text-text-hover flex items-center gap-1 p-0"
        onClick={handleClickGiveTip}
        aria-label={t('label')}
      >
        <StarCircleIcon />
        <span>{t('label')}</span>
      </Button>
    </div>
  )
}

export function CoinsAnimation({ delay, duration }: { delay: number; duration: number }) {
  const offsetX = useConstant(() => randomBetween(-150, 150))
  const rotateXTo = useConstant(() => randomBetween(-360, 360))
  const bottom = useConstant(() => randomBetween(20, 60))

  const values = useLinearProgress({
    duration,
    from: 0,
    to: 1,
    delay,
  })

  const eased = useTransform(values.value, cubicBezier(0, 1, 0.5, 2))
  const y = useTransform(eased, value => -bottom * value)
  const x = useTransform(values.value, value => `${value * offsetX}%`)
  const opacity = useTransform(values.value, [0, 0.1, 0.6, 1], [0, 1, 1, 0])
  const rotateZ = useTransform(values.value, value => `${value * rotateXTo}deg`)

  return (
    <motion.div
      className="absolute left-[40%] top-0 z-[100] origin-center saturate-200"
      style={{ x, y, opacity, rotateZ }}
    >
      <CoinIcon />
    </motion.div>
  )
}
