import * as React from 'react'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'

import { FireIcon, PsychologyIcon, TrendingUpIcon } from '@ask-cf/assets/icons'
import { StyledButton } from '@ask-cf/components/styled-button'
import { FeedSortingOption, useFeedSorting } from '@ask-cf/contexts/feed-sorting-context'
import { ASK_CF_FF_FEED_SORT_UNANSWERED } from '@ask-cf/config'

export function FeedSortButtons({ onItemClick }: { onItemClick?: () => void }) {
  const t = useTranslations('ask_cf.components.feed')
  const { activeSortOption, setActiveSortOption } = useFeedSorting()

  const handleClick = (sortOption: FeedSortingOption) => {
    setActiveSortOption(sortOption)
    onItemClick?.()
  }

  return (
    <div className="m-2 mt-0 flex flex-col justify-start xl:m-0 xl:flex-row xl:flex-wrap xl:gap-2">
      <StyledButton
        variant="fill"
        size="medium"
        className={tw(
          'justify-start gap-1 border-0 p-2 xl:justify-center xl:p-3',
          activeSortOption === 'your-feed'
            ? 'text-text-blue bg-blue-200 font-medium'
            : 'xl:hover:text-text-primary hover:text-text-hover enabled:hover:bg-transparent xl:enabled:hover:bg-blue-200',
        )}
        onClick={() => handleClick('your-feed')}
      >
        <TrendingUpIcon />
        <span className="text-sm leading-[20px]">{t('your_feed')}</span>
      </StyledButton>
      <StyledButton
        variant="fill"
        size="medium"
        className={tw(
          'justify-start gap-1 border-0 p-2 text-left xl:justify-center xl:p-3',
          activeSortOption === 'discovery-feed'
            ? 'text-text-blue bg-blue-200 font-medium'
            : 'xl:hover:text-text-primary hover:text-text-hover enabled:hover:bg-transparent xl:enabled:hover:bg-blue-200',
        )}
        onClick={() => handleClick('discovery-feed')}
      >
        <FireIcon />
        <span className="text-sm leading-[20px]">{t('discovery_feed')}</span>
      </StyledButton>
      {ASK_CF_FF_FEED_SORT_UNANSWERED ? (
        <StyledButton
          variant="fill"
          size="medium"
          className={tw(
            'justify-start border-0 p-2 text-left xl:justify-center xl:p-3',
            activeSortOption === 'un-answered'
              ? 'text-text-blue bg-blue-200 font-medium'
              : 'xl:hover:text-text-primary hover:text-text-hover enabled:hover:bg-transparent xl:enabled:hover:bg-blue-200',
          )}
          onClick={() => handleClick('un-answered')}
        >
          <PsychologyIcon />
          <span className="text-sm leading-[20px]">{t('un_answered')}</span>
        </StyledButton>
      ) : null}
    </div>
  )
}
